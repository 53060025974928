import { render, staticRenderFns } from "./RightView.vue?vue&type=template&id=e4647672&scoped=true&"
import script from "./RightView.vue?vue&type=script&lang=js&"
export * from "./RightView.vue?vue&type=script&lang=js&"
import style0 from "./RightView.vue?vue&type=style&index=0&id=e4647672&prod&scoped=true&lang=less&"
import style1 from "./RightView.vue?vue&type=style&index=1&id=e4647672&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4647672",
  null
  
)

export default component.exports